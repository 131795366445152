.menu {
  position: relative;
  width: 800px;
  /* height: 500px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu li {
  position: absolute;
  left: 0;
  top: 10vh;
  list-style: none;
  transform-origin: 400px;
  transition: 0.5s;
  transition-delay: calc(0.1s * var(--i));
  transform: rotate(0deg) translateX(0px) translateY(-500px);
}

.menu.active li {
  transform: rotate(calc(180deg / -4 * var(--i))) translateX(0px);
}

.menu li a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 175px;
  height: 175px;
  /* background: linear-gradient(
    179deg,
    rgb(0, 0, 0) -55%,
    rgb(0, 140, 60) 62%,
    rgb(0, 0, 0) 177%
  ); */
  border-radius: 50%;
  transform: rotate(calc(180deg / 4 * var(--i)));
  /* box-shadow: 0 3px 4px rgba(0, 0, 0, 0.15); */
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5);

  color: #ffffff;
  transition: 0.5s;
  text-decoration: none; /* Agregado para evitar el subrayado de enlaces */
}

.ciruclo-1 {
  background: linear-gradient(135deg, rgb(0, 55, 24), 30%, rgb(0, 187, 77));
}
.ciruclo-2 {
  background: linear-gradient(45deg, rgb(0, 55, 24), 30%, rgb(0, 187, 77));
}
.ciruclo-3 {
  background: linear-gradient(360deg, rgb(0, 55, 24), 30%, rgb(0, 187, 77));
}

.ciruclo-4 {
  background: linear-gradient(315deg, rgb(0, 55, 24), 30%, rgb(0, 187, 77));
}

.ciruclo-5 {
  background: linear-gradient(225deg, rgb(0, 55, 24), 30%, rgb(0, 187, 77));
}

.menu li a:hover {
  color: rgb(217, 217, 217);
  transform: rotate(calc(180deg / 4 * var(--i))) scale(1.2);
}

.buscador {
  position: absolute;
  right: 0;
}

.toggle {
  position: absolute;
  width: 30px;
  height: 30px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  cursor: pointer;
  /* font-size: 2em; */
  transition: transform 1.25s;
}

.menu.active .toggle {
  transform: rotate(360deg);
}

.logo_img {
  position: absolute;
  height: 9vh;
  left: 0;
}

.link_img {
  position: absolute;
  height: 9vh;
  left: 0;
}
.fuente {
  font-family: "Open Sans", sans-serif;
}

@media (max-width: 768px) {
  .menu {
    position: relative;
    width: 350px;
    /* height: 500px; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .menu li {
    position: absolute;
    left: 0;
    top: 10vh;
    list-style: none;
    transform-origin: 175px;
    transition: 0.5s;
    transition-delay: calc(0.1s * var(--i));
    transform: rotate(0deg) translateX(0px) translateY(-500px);
  }

  .menu li a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75px;
    height: 75px;
    background: linear-gradient(
      179deg,
      rgb(0, 0, 0) -55%,
      rgb(0, 140, 60) 62%,
      rgb(0, 0, 0) 177%
    );
    border-radius: 50%;
    transform: rotate(calc(180deg / 4 * var(--i)));
    /* box-shadow: 0 3px 4px rgba(0, 0, 0, 0.15); */
    box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5);
    font-size: 0.5rem;
    color: #ffffff;
    transition: 0.5s;
    text-decoration: none; /* Agregado para evitar el subrayado de enlaces */
  }

  .buscador {
    top: 45vh;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }

  .mobiles {
    display: none;
    opacity: 0;
    /* transition: opacity 0.5s ease-in-out; */
  }

  /* .logo_img {
    position: absolute;
    height: 5vh;
  } */

  .link_img {
    position: absolute;
    height: 5vh;
  }
}
