.contenedorProducots {
  background-color: black;
  margin-top: 10vh;
  padding-top: 5vh;
}

.highlight-PRODUCTO {
  color: #088c38; /* Cambia este color según tus necesidades */
  text-shadow: 0px 2px 10px #088c38;
}

.product-logo-PROEX {
  max-height: 80px;

  object-fit: contain;
}

.contenedorProducotsGreen {
  background-color: #4cb050;
  margin-top: 10vh;
  padding-top: 5vh;
}

.img_estilo {
  border-radius: 15px;
  width: 50%;
  text-align: center;
}

.titulo_producto {
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  text-align: center;
  font-size: 5rem;
  color: white;
  text-shadow: 0px 2px 10px rgb(255, 255, 255);
}

.desc_producto {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  text-align: center;
  font-size: 1.5rem;
  color: rgb(162, 162, 162);
  text-shadow: 0px 2px 10px rgb(255, 255, 255);
}

.desc_producto.green {
  color: #ffffff !important;
}

.tabla-container {
  border: 3px solid #fff;
  border-radius: 8px;
  padding: 10px;
}

.tabla {
  width: 100%;
  border-collapse: collapse;
}

.titulo_formula {
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 15px;
  font-family: "Open Sans", sans-serif;
}

.formula_producto {
  color: white;
  font-size: 1.25rem;
  margin-top: 15px;
  margin-bottom: 15px;
  font-family: "Open Sans", sans-serif;
}

.border_cabezera_1 {
  border-right: 1px solid #ffffff; /* Borde a la derecha de las celdas de datos */
  border-bottom: 1px solid #ffffff;
  font-family: "Open Sans", sans-serif;
  color: white;
  font-weight: bold;
  text-align: center;
  padding: 8px;
}

.border_cabezera_2 {
  border-left: 1px solid #ffffff; /* Borde a la derecha de las celdas de datos */
  border-bottom: 1px solid #ffffff;
  font-family: "Open Sans", sans-serif;
  color: white;
  font-weight: bold;
  text-align: center;
  padding: 8px;
}

.componente {
  border-right: 1px solid #ffffff; /* Borde a la derecha de las celdas de datos */
  border-bottom: 1px solid #ffffff;
  padding: 4px;
  text-align: center;
  color: #fff;
  font-family: "Open Sans", sans-serif;
}

.componente_ultimo {
  border-right: 1px solid #ffffff; /* Borde a la derecha de las celdas de datos */
  padding: 4px;
  text-align: center;
  color: #fff;
  font-family: "Open Sans", sans-serif;
}

.porcentaje {
  border-left: 1px solid #ffffff; /* Borde a la derecha de las celdas de datos */
  border-bottom: 1px solid #ffffff;
  padding: 4px;
  text-align: center;
  color: #fff;
  font-family: "Open Sans", sans-serif;
}
.porcentaje_ultimo {
  border-left: 1px solid #ffffff; /* Borde a la derecha de las celdas de datos */
  padding: 4px;
  text-align: center;
  color: #fff;
  font-family: "Open Sans", sans-serif;
}

/* .tabla td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  color: #fff;
} */

.titulo_composicion {
  color: white;
  font-size: 1.5rem;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
}

.titulo_presentacion {
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 15px;
  font-family: "Open Sans", sans-serif;
}

@media (max-width: 768px) {
  .img_estilo {
    width: 100%;
  }
  .titulo_producto {
    font-size: 3rem;
  }
}

/* Define la animación */
@keyframes fadeOutIn {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Aplica la animación al texto */
.translated-text {
  animation: fadeOutIn 0.5s ease-in-out;
}
