.slick-next {
  right: 25px !important; /* Ajusta este valor según tus necesidades para posicionar el botón correctamente */
  background: chartreuse;
}

.slick-prev {
  left: 25px !important;
  z-index: 1;
}

.slick-prev:before,
.slick-next:before {
  color: #008c3c !important;
}

.carousel-slide {
  background-color: aliceblue;
}

.product-image {
  max-width: 100%; /* Ancho máximo del 100% del contenedor */
  max-height: 200px; /* Altura máxima, ajusta según tus necesidades */
  object-fit: contain; /* Puedes usar "cover" o "contain" según prefieras */
  border-radius: 15px;
}

.product-slide {
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 25px;
  padding-bottom: 25px;
}

.product-contenedor {
  box-shadow: 0px 0px 10px rgb(0, 0, 0);
  padding: 10px;
  border-radius: 15px;
  background-color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 300px;
  height: 100%;
  color: white;
  transition: transform 0.5s ease, box-shadow 0.5s ease,
    background-color 0.5s ease, color 0.5s ease, text-shadow 0.5s ease;
}

.product-contenedor:hover {
  transform: scale(1.05);
  box-shadow: 0px 0px 20px rgb(139, 139, 139);
  background-color: #000000;
  color: #ffffff;
  text-shadow: 0px 2px 10px rgb(255, 255, 255);
}

.product-contenedor.green {
  box-shadow: 0px 0px 10px rgb(0, 0, 0);
  padding: 10px;
  border-radius: 15px;
  background-color: #4cb050;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 300px;
  height: 100%;
  color: white;
  transition: transform 0.5s ease, box-shadow 0.5s ease,
    background-color 0.5s ease, color 0.5s ease, text-shadow 0.5s ease;
}

.product-contenedor.green:hover {
  transform: scale(1.05);
  box-shadow: 0px 0px 20px rgb(139, 139, 139);
  background-color: #4cb050;
  color: #ffffff;
  text-shadow: 0px 2px 10px rgb(255, 255, 255);
}

.nombre_producto {
  font-family: "Open Sans", sans-serif;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

/* Estilos para el botón "Ver Detalles" */
.product-button {
  background-color: #17672f; /* Color de fondo del botón */
  color: #fff; /* Color del texto del botón */
  padding: 10px 20px; /* Espaciado interior del botón */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

/* Estilos para el botón "Ver Detalles" */
.product-button.green {
  background-color: #000; /* Color de fondo del botón */
  color: #fff; /* Color del texto del botón */
  padding: 10px 20px; /* Espaciado interior del botón */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.text_boton {
  font-family: "Open Sans", sans-serif;
}

.product-button:hover {
  background-color: #008c3c; /* Cambia el color de fondo al pasar el ratón sobre el botón */
}

.product-button.green:hover {
  background-color: #000; /* Cambia el color de fondo al pasar el ratón sobre el botón */
}

.product-carousel-container {
  padding-bottom: 20px;
  padding-top: 20px;
}
