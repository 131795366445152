/* Estilos para el footer */
footer {
  background-color: #1d1d1b;
  color: #fff;
  padding: 20px 0 0 0;
  margin-top: 20px;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-logo {
  text-align: center;
}

.footer-logo img {
  /* width: 400px; */
}

.footer-logo h3 {
  margin: 10px 0;
  font-size: 24px;
}

.footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center; /* Centra los elementos horizontalmente */
  align-items: center;
}

.footer-links li {
  margin-right: 20px;
}

.footer-links a {
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  transition: color 0.3s;
}

.footer-links a:hover {
  color: #f39c12;
}

.footer-contact {
  text-align: center;
}

.footer-contact h4 {
  font-size: 20px;
  margin: 10px 0;
}

.footer-contact p {
  font-size: 16px;
  margin: 5px 0;
}

.footer-bottom {
  text-align: center;
  background-color: #222;
  padding: 10px 0;
}

.footer-bottom p {
  margin: 0;
  font-size: 14px;
}

/* Estilos para hacer el footer responsive */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    text-align: center;
  }

  .footer-links ul {
    margin-top: 20px;
  }

  .footer-links li {
    margin: 0;
    margin-bottom: 10px;
  }

  .footer-bottom {
    font-size: 12px;
  }
}

/* Define la animación */
@keyframes fadeOutIn {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Aplica la animación al texto */
.translated-text {
  animation: fadeOutIn 0.5s ease-in-out;
}
