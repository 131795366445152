.language-selector {
  position: fixed;
  bottom: 20px; /* Ajusta este valor según sea necesario */
  right: 20px; /* Ajusta este valor según sea necesario */
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}
